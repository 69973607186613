<template>
  <div id="transfer" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3">
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form @submit.prevent="passes(initializeTransfer)">
            <SdInput
              type="text"
              rules="required"
              label="Current School Class"
              v-model="student.schoolClass.name"
              placeholder="Name"
              readonly
            />

            <SdSelect
              rules="required"
              label="New School Class"
              v-model="newSchoolClass"
            >
              <option
                v-for="schoolClass in schoolClasses"
                :key="schoolClass.id"
              >
                {{ schoolClass.name }}
              </option>
            </SdSelect>

            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized is-pulled-right mt-3"
            >
              Transfer
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <!-- Delete -->
    <app-modal
      id="transfer-student"
      context="update"
      @update="submit"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add"
        ><p>
          {{
            `You want to transfer this student from ${student.schoolClass.name} to ${newSchoolClass}?`
          }}
        </p>
        <p>Proceed?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'

export default {
  name: 'student',
  data() {
    return {
      schoolId: null,
      newSchoolClassId: null,
      newSchoolClass: null,
      termId: null,
      pageTitle: 'Transfer',
      student: {
        id: null,
        name: '',
        schoolClass: {
          id: null,
          name: null,
        },
      },
      schoolClasses: [],
    }
  },
  apollo: {
    student: {
      query: gql`
        query studentId($id: ID!) {
          student(id: $id) {
            id
            name
            schoolClass {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
    },
    schoolClasses: {
      query: SCHOOL_CLASSES,
      variables() {
        return { schoolId: parseInt(this.$route.params.school_id) }
      },
    },
  },
  watch: {
    student(data) {
      this.$store.commit('setSubMenus', [
        {
          name: 'Student Info',
          route: `/school/${this.$route.params.school_id}/school_class/${data.schoolClass.id}/student_info/${this.$route.params.id}`,
        },
      ])
    },
    newSchoolClass(n) {
      this.newSchoolClassId = parseInt(
        this.schoolClasses.filter((school_class) => school_class.name === n)[0]
          .id
      )
    },
  },
  methods: {
    initializeTransfer() {
      this.openModal()
    },
    submit() {
      const id = parseInt(this.student.id)
      const oldSchoolClassId = parseInt(this.student.schoolClass.id)
      this.$apollo
        .mutate({
          mutation: gql`
            mutation transferStudent(
              $id: Int!
              $oldSchoolClassId: Int!
              $newSchoolClassId: Int!
            ) {
              transferStudent(
                input: {
                  id: $id
                  oldSchoolClassId: $oldSchoolClassId
                  newSchoolClassId: $newSchoolClassId
                }
              ) {
                errors
              }
            }
          `,
          variables: {
            id: id,
            oldSchoolClassId: oldSchoolClassId,
            newSchoolClassId: this.newSchoolClassId,
          },
        })
        .then((response) => {
          if (response.data.transferStudent.errors.length === 0) {
            this.closeModal()
            this.$buefy.notification.open({
              duration: 5000,
              message: 'Successfully transfered',
              position: 'is-top',
              type: 'is-info',
              hasIcon: true,
            })
            this.$router.push(
              `/school/${this.schoolId}/students/${this.newSchoolClassId}`
            )
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    fetchTerm().then((term) => {
      this.termId = term.id
    })
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
  },
}
</script>
